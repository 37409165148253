<template>
  <div class="delimiter">
    <section
      class="
      xl:pt-56 xl:pb-56 xl:px-44
      lg:pt-36 lg:pb-36 lg:px-32
      md:pt-28 md:pb-28 md:px-24
      pt-28 pb-28 px-8"
      ref="trends"
    >
      <div
        class="flex items-center mb-4 enter-reports enter-fade-up
        md:text-base
        text-sm"
      >
        <div class="title-bar"></div>
        <span>{{ $t('home.trends') }}</span>
      </div>
      <h1
        class="relative font-bold mb-20 enter-reports enter-fade-up
        md:text-5xl
        text-3xl"
      >
        {{ $t('home.trendsReport') }}
      </h1>
      <div class="flex justify-center flex-wrap -mb-22">
        <trend-report
          v-for="report in section.reports"
          :key="report.title"
          component-class="enter-reports enter-fade-up
            md:w-1/3
            w-full"
          :title="report.title"
          :image="report.image"
          @click="selectPdf(report)"
        ></trend-report>
      </div>
    </section>
  </div>
</template>

<script>
// Components
import TrendReport from '@/components/TrendReport.vue'

// JSON
import reports from '@/assets/js/reports.js'

// Utilites
import initEnterAnimations from '@/assets/js/enter-animations'

// Store
import { store } from '../main'

export default {
  name: 'Reports',
  components: { TrendReport },
  data: () => ({ section: { reports: [] }, sharedState: store.state }),
  methods: {
    setSection(newValue) {
      const { id, file } = newValue
      const section = reports.find(r => r.id === id)
      if (!section) {
        this.$router.push(`/`)
      }
      this.section = section
      store.showFile(file && `/pdf/${file}`)
    },
    selectPdf(report) {
      if (!('file' in this.$route.params)) {
        const { id } = this.$route.params
        const file = this.$t(`reports.${report.title}.file`).replace(
          '/pdf/',
          ''
        )
        this.$router.push(`/reports/${id}/${file}`)
      }
    },
  },
  watch: {
    '$route.params': function(newValue) {
      this.setSection(newValue)
    },
    'sharedState.file': function(newValue) {
      if (!newValue) {
        const { id } = this.$route.params
        this.$router.push(`/reports/${id}`)
      }
    },
  },
  mounted() {
    this.setSection(this.$route.params)
    setTimeout(
      () =>
        (this.disposeEnterAnimations = initEnterAnimations('.enter-reports'))
    )
  },
  unmounted() {
    this.disposeEnterAnimations()
  },
}
</script>
